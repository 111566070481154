import React from 'react';
//import OnVisible from 'react-on-visible';
import './Header.scss';
import HomePageImageQuery from '../../Image/HomePageImageQuery'
import { ImagePass } from '../../Image/ImagePass';
import { Breadcrumbs } from '../../Breadcrumbs';
import { decodeEntities } from '../../../utils/helpers';
import Carousel from '../../Carousel';

export const Header = (props) => {
  const {
    isHomepageHeader,
    images,
    title,
    content,
    location
  } = props;
  const settings = {
    container: 'header-carousel',
    nav: false,
    mouseDrag: false,
    controls: false,
    loop: true,
    autoplay: true,
    slideBy: 1,
    speed: 100,
    mode: 'gallery',
  };
  return (
    <section className={`page-header${isHomepageHeader ? ' homepage-header' : ''}`}>
      {(isHomepageHeader && images && images.length) && (
        <>
        <Carousel settings={settings}>
          {location.pathname === "/" && <div className="space"><div/></div>}
          {images.map((image, slide_count) =>
            <div className={`header-image`} key={slide_count}>
               <ImagePass src={image} className="background-image" />
            </div>
          )}
        </Carousel>
        {location.pathname === "/" && <div className="home-background"><HomePageImageQuery className="background-image"/></div>}
        </>
      )}
      <div className="page-header-content">
        <div className="wrap">
          <div className="inner">
            {!isHomepageHeader && <Breadcrumbs location={location} />}
            <h1>{decodeEntities(title)}</h1>
            {content && <div className="content" dangerouslySetInnerHTML={{__html: content}} />}
          </div>
        </div>
      </div>
    </section>
  )
}
