import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { ImagePass } from './ImagePass'

class HomePageImageQuery extends React.Component {
  state = {
    fcpLoaded: false,
  }
  render() {
    const { data, className } = this.props
    const image = data.wordpressPage.acf.background
    if (!image) return null
    return <ImagePass src={image} className={`${className ? ` ${className}` : 'background' }`} returnBase64={true} fcpLoaded={this.state.fcpLoaded} />
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query HomePageImageQuery {
        wordpressPage(path: {eq: "/"}) {
          acf {
            background {
              localFile {
                publicURL
                childImageSharp {
                  base64: sizes(base64Width: 1110) {
                    base64
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <HomePageImageQuery data={data} {...props} />}
  />
)
